//
//  tableList.js
//  datatown
//
//  Created by Mumakil on 2022. 8. 30..
//  Copyright © 2022년 Melephant. All rights reserved.
//

// common
import { TableKey } from 'constants/key';

// string
import { useTranslation } from 'react-i18next';


/**
 * @protocol OrdinalTableColumnList
 * @date 2022/11/28
 * @brief 기수 리스트 항목 목록 반환
 */
export function OrdinalTableColumnList() {
    const { t } = useTranslation();
    return [{
        id: TableKey.TABLE_ORDINARY,
        name: t('word_ordinary'),
        isClickable: false,
    }, {
        id: TableKey.TABLE_MENTOR,
        name: t('word_mentor'),
        isClickable: false,
    }, {
        id: TableKey.TABLE_PERIOD,
        name: t('word_plan'),
        isClickable: false,
    }, {
        id: TableKey.TABLE_STATUS,
        name: t('word_progress'),
        isClickable: false,
    }];
}
