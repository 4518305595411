// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".helpM2mDetailScreen_qna_container__PsNNB {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.helpM2mDetailScreen_qna_header__jZlL7 {\n    display: flex;\n    justify-content: space-between;\n}\n\n.helpM2mDetailScreen_qna_header__jZlL7 > h4 {\n    color: #f26625;\n    font-size: 13px;\n}\n\n.helpM2mDetailScreen_qna_footer__chPiH {\n    display: flex;\n    margin-top: 20px;\n}\n\n.helpM2mDetailScreen_answer_container__fHaw5 {\n    width: 100%;\n    margin-top: 50px;\n}\n\n.helpM2mDetailScreen_answer_footer__YhI4e {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.helpM2mDetailScreen_date__CUjAH {\n    display: block;\n    font-size: 12px;\n    text-align: right;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.helpM2mDetailScreen_image__4lsjp {\n    max-height: 120px;\n    margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/css/screen/helpM2mDetailScreen.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".qna_container {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.qna_header {\n    display: flex;\n    justify-content: space-between;\n}\n\n.qna_header > h4 {\n    color: #f26625;\n    font-size: 13px;\n}\n\n.qna_footer {\n    display: flex;\n    margin-top: 20px;\n}\n\n.answer_container {\n    width: 100%;\n    margin-top: 50px;\n}\n\n.answer_footer {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.date {\n    display: block;\n    font-size: 12px;\n    text-align: right;\n    margin-left: 10px;\n    margin-right: 10px;\n}\n\n.image {\n    max-height: 120px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qna_container": "helpM2mDetailScreen_qna_container__PsNNB",
	"qna_header": "helpM2mDetailScreen_qna_header__jZlL7",
	"qna_footer": "helpM2mDetailScreen_qna_footer__chPiH",
	"answer_container": "helpM2mDetailScreen_answer_container__fHaw5",
	"answer_footer": "helpM2mDetailScreen_answer_footer__YhI4e",
	"date": "helpM2mDetailScreen_date__CUjAH",
	"image": "helpM2mDetailScreen_image__4lsjp"
};
export default ___CSS_LOADER_EXPORT___;
