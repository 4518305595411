//
//  nftScreen.js
//  datatown
//
//  Created by Mumakil on 2022. 12. 19..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useEffect,
} from 'react';

// common
import ConstantValue from 'constants/value';
import ConstantPath from 'constants/path';
import { NftList } from 'constants/list';

// util
import DataUtil from 'util/dataUtil';

// string
import { useTranslation } from 'react-i18next';

// component
import Layout from 'components/layout/layout';


/**
 * @protocol NftScreen
 * @date 2022/12/19
 * @brief NFT 소개 화면
 */
function NftScreen() {
    const { t } = useTranslation();

    const s2eContentList = NftList.S2EContentList();
    const dgContentList = NftList.DGContentList();

    useEffect(() => {
    }, []);

    /**
     * S2E 메뉴 컨텐츠 목록 반환
     * @returns {Array} 컨텐츠 목록
     */
    const renderS2eContentList = () => {
        var itemList = [];

        for( var i = 0; i < s2eContentList.length; i++ ) {
            var content = s2eContentList[i];
            
            if( DataUtil.validateString(content.title) ) {
                itemList.push(<br key={"br_s2e" + String(i) + "1"}/>);
                itemList.push(<br key={"br_s2e" + String(i) + "2"}/>);
                itemList.push(<h4 key={"h4_s2e" + String(i)}>{content.title}</h4>);
                itemList.push(<br key={"br_s2e" + String(i) + "3"}/>);
            }

            if( DataUtil.validateString(content.slogan) ) {
                itemList.push(<center key={"center_s2e" + String(i)}><h4>{content.slogan}</h4></center>);
                itemList.push(<br key={"br_s2e" + String(i) + "4"}/>);
            }

            if( DataUtil.validateString(content.description) ) {
                itemList.push(<p key={"p_s2e" + String(i)}>{content.description}</p>);
            }

            if( DataUtil.validateArray(content.list) ) {
                for( var j = 0; j < content.list.length; j++ ) {
                    if( DataUtil.validateString(content.list[j].title) ) {
                        itemList.push(<h6 key={"h6_s2e" + String(i) + String(j)}><li>{content.list[j].title}</li></h6>);
                    }
                    if( DataUtil.validateString(content.list[j].description) ) {
                        itemList.push(<p key={"p_2_s2e" + String(i) + String(j)}>{content.list[j].description}</p>);
                        itemList.push(<br key={"br_s2e" + String(i) + "5" + String(j)}/>);
                    }
                }
            }

            if( DataUtil.validateString(content.image1) ) {
                itemList.push(<figure key={"figure_1_s2e" + String(i)}><img src={content.image1} alt=""/></figure>);
            }

            if( DataUtil.validateString(content.image2) ) {
                itemList.push(<figure key={"figure_2_s2e" + String(i)}><img src={content.image2} alt=""/></figure>);
            }
        }
        return itemList;
    }

    /**
     * 데이터장군 메뉴 컨텐츠 목록 반환
     * @returns {Array} 컨텐츠 목록
     */
    const renderDGContentList = () => {
        var itemList = [];

        for( var i = 0; i < dgContentList.length; i++ ) {
            var content = dgContentList[i];
            
            if( DataUtil.validateString(content.title) ) {
                itemList.push(<br key={"br_dg" + String(i) + "1"}/>);
                itemList.push(<br key={"br_dg" + String(i) + "2"}/>);
                itemList.push(<h4 key={"h4_dg" + String(i)}>{content.title}</h4>);
                itemList.push(<br key={"br_dg" + String(i) + "3"}/>);
            }

            if( DataUtil.validateString(content.description) ) {
                itemList.push(<p key={"p_dg" + String(i)}>{content.description}</p>);
            }

            if( DataUtil.validateString(content.image1) ) {
                itemList.push(<figure key={"figure_dg_1" + String(i)}><img src={content.image1} alt=""/></figure>);
            }

            if( DataUtil.validateString(content.image2) ) {
                itemList.push(<figure key={"figure_dg_2" + String(i)}><img src={content.image2} alt=""/></figure>);
            }
        }
        return itemList;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}>
            <div className="gap no-gap" style={{textAlign: 'left'}}>
                <div className="top-area mate-black low-opacity">
                    <div className="bg-image" style={{backgroundImage: 'url(assets/images/image/img_bg_top.jpg)'}}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-subject">
                                    <a className="tagz" href="#S2E" title="" onClick={e => e.preventDefault()}>{t('nft_s2e')}</a>
                                    <h4>{t('nft_title')}</h4>
                                    <ul className="nav nav-tabs post-detail-btn">
                                        <li className="nav-item">
                                            <a className="active" href="#s2e" data-toggle="tab">
                                                {t('nft_section_s2e')}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="" href="#dg" data-toggle="tab">
                                                {t('nft_section_dg')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="gap" style={{textAlign: 'left'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div id="page-contents" className="row merged20">
                                        <div className="col-lg-8">
                                            <div className="tab-content">
                                                <div className="tab-pane fade active show" id="s2e" >
                                                    <div className="main-wraper">
                                                        <div className="post-detail">
                                                            <div className="blog-title">
                                                                <h3>{t('nft_s2e_title')}</h3><br/>
                                                            </div>
                                                            <div className="blog-details-meta">
                                                                {renderS2eContentList()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show" id="dg" >
                                                    <div className="main-wraper">
                                                        <div className="post-detail">
                                                            <div className="blog-title">
                                                                <h3>{t('nft_dg_title')}</h3><br/>
                                                            </div>
                                                            <div className="blog-details-meta">
                                                                <h4>{t('nft_dg_process_title')}</h4>
                                                                <figure><img src={'assets/images/image/img_nft_process.png'} alt=""/></figure>
                                                                <h6>{t('nft_dg_process_step1_title')}</h6>
                                                                <p>{t('nft_dg_process_step1_description')}</p><br/>
                                                                <h6>{t('nft_dg_process_step2_title')}</h6>
                                                                <p>{t('nft_dg_process_step2_description')}</p><br/>
                                                                <h6>{t('nft_dg_process_step3_title')}</h6>
                                                                <p>{t('nft_dg_process_step3_description')}</p><br/>
                                                                <h6>{t('nft_dg_process_step4_title')}</h6>
                                                                <p>{t('nft_dg_process_step4_description')}</p><br/>
                                                            </div>
                                                            <div className="blog-details-meta">
                                                                <h4>{t('nft_dg_document_title')}</h4><br/>
                                                                <p>{t('nft_dg_document_description')}</p><br/>
                                                                <a href={ConstantValue.NFT_LINK1} target='_blank' rel='noreferrer'><b>
                                                                    {t('nft_dg_document_link1')}
                                                                </b></a><br/>
                                                                <a href={ConstantValue.NFT_LINK2} target='_blank' rel='noreferrer'><b>
                                                                    {t('nft_dg_document_link2')}
                                                                </b></a><br/>
                                                                <a href={ConstantValue.NFT_LINK3} target='_blank' rel='noreferrer'><b>
                                                                    {t('nft_dg_document_link3')}
                                                                </b></a><br/>
                                                            </div>
                                                            <div className="blog-details-meta">
                                                                {renderDGContentList()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <aside className="sidebar static right">
                                                <div className="widget">
                                                    <h4 className="widget-title">{t('nft_widget_mentor_title')}</h4>
                                                    <div className="ask-question">
                                                        <i className="icofont-question-circle"></i>
                                                        <h6>{t('nft_widget_mentor_description')}</h6>
                                                        <a href={ConstantPath.PATH_SOLUTION_APPLY} title="">{t('button_help_mentor')}</a>
                                                    </div>
                                                </div>
                                                <div className="widget">
                                                    <h4 className="widget-title">{t('nft_widget_webinar_title')}</h4>
                                                    <div className="rec-events bg-purple">
                                                        <i className="icofont-gift"></i>
                                                        <h6><a title="" href="/">{t('nft_widget_webinar_content1')}</a></h6>
                                                        <img alt="" src="assets/images/image/img_clock.png"/>
                                                    </div>
                                                    <div className="rec-events bg-blue">
                                                        <i className="icofont-microphone"></i>
                                                        <h6><a title="" href="/">{t('nft_widget_webinar_content2')}</a></h6>
                                                        <img alt="" src="assets/images/image/img_clock.png"/>
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </Layout>
    );
}

export default NftScreen;