// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".helpNoticeDetailScreen_container__vJtiZ {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.helpNoticeDetailScreen_header__YLmlp {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 30px;\n}\n\n.helpNoticeDetailScreen_subject__I4Swz {\n    display: flex;\n}\n\n.helpNoticeDetailScreen_subject__I4Swz > h4 {\n    color: #f26625;\n    font-size: 13px;\n}\n\n.helpNoticeDetailScreen_subject__I4Swz > h5 {\n    color: #000;\n    font-size: 15px;\n    margin-left: 50px;\n}\n\n.helpNoticeDetailScreen_date__686IY {\n    display: block;\n    font-size: 12px;\n    text-align: right;\n    margin-left: 10px;\n    margin-right: 10px;\n}", "",{"version":3,"sources":["webpack://./src/css/screen/helpNoticeDetailScreen.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".container {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 30px;\n}\n\n.subject {\n    display: flex;\n}\n\n.subject > h4 {\n    color: #f26625;\n    font-size: 13px;\n}\n\n.subject > h5 {\n    color: #000;\n    font-size: 15px;\n    margin-left: 50px;\n}\n\n.date {\n    display: block;\n    font-size: 12px;\n    text-align: right;\n    margin-left: 10px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "helpNoticeDetailScreen_container__vJtiZ",
	"header": "helpNoticeDetailScreen_header__YLmlp",
	"subject": "helpNoticeDetailScreen_subject__I4Swz",
	"date": "helpNoticeDetailScreen_date__686IY"
};
export default ___CSS_LOADER_EXPORT___;
