// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kakaoLoginButton_kakao_btn__Oczq3 {\n    float: left;\n    background-repeat: no-repeat;\n    background-size : cover;\n    margin: 10px auto;\n    /* padding: -10px; */\n    color: transparent;\n    width: 300px;\n    height: 45px;\n}", "",{"version":3,"sources":["webpack://./src/css/component/kakaoLoginButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,4BAA4B;IAC5B,uBAAuB;IACvB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".kakao_btn {\n    float: left;\n    background-repeat: no-repeat;\n    background-size : cover;\n    margin: 10px auto;\n    /* padding: -10px; */\n    color: transparent;\n    width: 300px;\n    height: 45px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kakao_btn": "kakaoLoginButton_kakao_btn__Oczq3"
};
export default ___CSS_LOADER_EXPORT___;
