// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dayTimetableInput_container__Xw3v\\+ {\n    display: block;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.dayTimetableInput_container__Xw3v\\+ label {\n    margin-left: 10px;\n}\n\n.dayTimetableInput_container__Xw3v\\+ input[type=\"checkbox\"] {\n    accent-color: #f26625;\n}\n\n.dayTimetableInput_container__Xw3v\\+ > div {\n    display: inline-block;\n    width: 150px;\n    margin-left: 10px;\n    margin-bottom: 0px;\n    accent-color: #f00;\n}\n\ninput::-webkit-contacts-auto-fill-button {\n    visibility: hidden;\n}", "",{"version":3,"sources":["webpack://./src/css/component/dayTimetableInput.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    display: block;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.container label {\n    margin-left: 10px;\n}\n\n.container input[type=\"checkbox\"] {\n    accent-color: #f26625;\n}\n\n.container > div {\n    display: inline-block;\n    width: 150px;\n    margin-left: 10px;\n    margin-bottom: 0px;\n    accent-color: #f00;\n}\n\ninput::-webkit-contacts-auto-fill-button {\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dayTimetableInput_container__Xw3v+"
};
export default ___CSS_LOADER_EXPORT___;
