//
//  solutionProjectScreen.js
//  datatown
//
//  Created by Mumakil on 2023. 1. 13..
//  Copyright © 2022년 Melephant. All rights reserved.
//

import React, {
    useState,
} from 'react';

// common
import ConstantPath from 'constants/path';
import ConstantValue from 'constants/value';
import { CommonKey } from 'constants/key';

// string
import { useTranslation } from 'react-i18next';

// network
import NetworkSolution from 'network/networkSolution';

// info
import { PROJECTINFO_LIST } from 'info/solution/projectInfo';

// redux
import { useDispatch } from 'react-redux';
import { popupShowDefault } from 'redux/actions/popupAction';

// component
import Layout from 'components/layout/layout';
import ProjectCell from 'components/cell/projectCell';
import PageList from 'components/list/pageList';

// package
import { useNavigate, useLocation } from "react-router-dom";


/**
 * @protocol SolutionProjectScreen
 * @date 2023/01/13
 * @brief 사수해법 팀프로젝트 화면
 */
function SolutionProjectScreen(props) {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [dataList, setDataList] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    
    // 데이터 요청 처리
    const fetchData = async (pageNum, pageSize) => {
        NetworkSolution.getRecruitProjectList((res) => {
            setDataList(res[PROJECTINFO_LIST]);
            setTotalCount(res[CommonKey.PAGE_TOTAL_COUNT]);
        }, (res) => {
            dispatch(popupShowDefault(t('alert_network_error')));
        }, pageNum, pageSize);
    };

    /**
     * 프로젝트 셀 ui 렌더링
     * @param {NoticeInfo} info 공지사항 정보
     * @returns {NoticeCell} 공지사항 셀
     */
    const renderContent = (info) => {
        return  <div className="col-lg-4 col-md-4 col-sm-4"
                    key={"cell" + String(info.projectId) + String(info.ordinalInfo.ordinal)}>
                    <ProjectCell data={info}
                        onCellPressed={(id, ordinal) => {
                            var path = ConstantPath.PATH_PROJECT_INTRO.split("/");
                            var strippedPath = path.slice(0, path.length-1).join("/");
                            navigate(strippedPath + '/' + id);
                    }}/>
                </div>;
    }

    return (
        <Layout
            visibleHeader={true}
            visibleFooter={true}
            visibleSolutionHeader={true}
            location={location.pathname}>
			<div className="gap" style={{textAlign: 'left'}}>
                
                {/* 추가내용 */}
                <div className="myBanner w-100">
                    <h2 className="w-100" style={{ margin: '15px' }}>
                        <svg className="mr-1" style={{ color: 'red' }} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2m6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
                        </svg>
                        <span className="m-1">Check! Fanwork's</span>
                    </h2>
                    <div className="video-container">
                        <div className="video-frame">
                            <iframe 
                                width="400" 
                                height="425" 
                                src="https://www.youtube.com/embed/3hcsXC4woIs?si=V2ip-gL0TooLp3ER" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                        <div className="video-frame">
                            <iframe 
                                width="400" 
                                height="425" 
                                src="https://www.youtube.com/embed/coPPyDhL8KQ?si=67LBtj0ZGPH2CFii" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                        <div className="video-frame">
                            <iframe 
                                width="400" 
                                height="425" 
                                src="https://www.youtube.com/embed/_LOv7VtMYuc?si=Fp9Qu6nMBuHR-sNJ" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                        <div className="video-frame">
                            <iframe 
                                width="400" 
                                height="425" 
                                src="https://www.youtube.com/embed/tHAMbgETR88?si=uR0s3E6FUS6lwTEj" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                        <div className="video-frame">
                            <iframe 
                                width="400" 
                                height="425" 
                                src="https://www.youtube.com/embed/rAsqmuT1f44?si=YmiVTynV719RnHph" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="main-wraper">
                                <PageList
                                    dataList={dataList}
                                    fetchData={fetchData}
                                    pageSize={ConstantValue.RECRUIT_PROJECT_PAGE_SIZE}
                                    pageRangeDisplayed={5}
                                    className="row"
                                    totalCount={totalCount}
                                    renderCell={renderContent}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SolutionProjectScreen;